import io from 'socket.io-client';
import { BaseURL } from 'src/assets/utils/http-request'
const token = window.localStorage.getItem('token');

const socket = io(BaseURL, {
    autoConnect: false,
    reconnection: true,
    reconnectionAttempts: 10,
    reconnectionDelay: 2000,
    reconnectionDelayMax: 10000,
    query: {
        token: token,
    },
});

export default socket; 